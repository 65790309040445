import HairStylesList from '../components/hairServices/HairStylesList'
import HairStyles from '../components/hairServices/HairStylesList'
import HairServiceData from '../Data/HairServiceData'

const Services = () => {
  return (
    <>
      <HairStylesList />
    </>
  )
}

export default Services
