const HairServiceData = [
  { name: 'Box Braids', price: 0 },
  { name: 'Knot Less Braids', price: 0 },
  { name: 'Jumbo Braids', price: 0 },
  { name: 'Micro Braids ', price: 0 },
  { name: 'Goddess Braids', price: 0 },
  { name: 'Cornrows', price: 0 },
  { name: 'Crotchet', price: 0 },
  { name: 'Dreadlocks ', price: 0 },
  { name: 'Re-twists', price: 0 },
  { name: 'Senegalese Twists', price: 0 },
  { name: 'Kinky Twists ', price: 0 },
  { name: 'Sew In', price: 0 },
  { name: 'Quick Weave', price: 0 },
]

export default HairServiceData
