import '../AboutUs/AboutUs.css'
const AboutUs = () => {
  return (
    <div className='about-us'>
      <h1>About</h1>
      <p>Abay's Hair Braiding services all of Minneapolis. The owner of Abay's has over 10 years of experience in the braiding industry. She is </p>
    </div>
  )
}

export default AboutUs
